.stock{
  margin-top: 8px;
  margin-right: 8px;
}

.items-description {
margin-top: 0.8rem !important;

padding-right: 8px !important;

padding: 0 25px;

width: calc(0.58333 * (100vw - 8px));

display: flex;

max-width: 100%;

color: black !important;

justify-content: space-between;
}

.color-tag{
height:20px;
display: block;
}

.color-box{
  display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  border-style: solid;
   border-color: black;
    border-width: 2px;
    border-radius:20px;
  overflow:hidden;
}
  
  .no_items_a{
    margin: 10px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      flex-direction: column;
  }
  
  .a-unordered-list{
    list-style-type: none;  
    padding-left:0;
  }

    div[data-fieldname=item_name]{
    padding: var(--padding-md) var(--padding-lg);
    width:250px;
    }

    input[data-fieldname=item_name]{
    width: 100%;
    }

    .bg_opacity {
      opacity: 0.6;
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1040;
      background-color: #000;
      display: block;
    }

    .display_none {
      display: none !important;
    }

    #user_cart {
      margin-bottom: 21px;
      margin-left: 21px;
    }

    .search_ensemble {
      width: 77%;
      margin: 0 1%;
    }

    .results-container {
      position: absolute;

      background-color: white;

      z-index: 1500;

      padding: 0 10px;

      width: inherit;
    }

    .flex-top {
      display: flex;

      flex-direction: column;
    }

    .search-box {
      width: 100%;

      position: relative;

      display: flex;

      bottom: 0;

      left: 0;

      right: 0;

      z-index: 1041;
    }

    .search-input {
      height: 42px;

      width: 100%;

      font-family: "Montserrat", sans-serif;

      font-size: 16px;

      padding: 15px 45px 15px 15px;

      background-color: #eaeaeb;

      color: #6c6c6c;

      border-radius: 6px;

      border: none;

      transition: all 0.4s;

      cursor: text;
    }

    .search-input:focus {
      border: none;

      outline: none;

      box-shadow: 0 1px 12px #b8c6db;

      -moz-box-shadow: 0 1px 12px #b8c6db;

      -webkit-box-shadow: 0 1px 12px #b8c6db;
    }

    .search-btn {
      background-color: transparent;

      font-size: 18px;

      padding: 6px 9px;

      margin-left: -45px;

      border: none;

      color: #6c6c6c;

      transition: all 0.4s;

      z-index: 10;
    }

    .search-btn:hover {
      transform: scale(1.2);

      cursor: pointer;

      color: black;
    }

    .search-btn:focus {
      outline: none;

      color: black;
    }

    #site-search-mb {
      height: 3rem;

      width: 100%;

      border-radius: 10px;
    }

    #site-search {
      max-height: 29px;

      width: 26%;

      margin-top: -90px;

      border-radius: 10px;

      z-index: 999;

      position: absolute;

      right: 23%;

      left: 27%;
    }

    .web-footer {
      padding: 33px;
    }

    .navbar-light {
      padding-right: 40px;
      padding-left: 40px;
    }

    .a-section {
      margin-left: 15px;
    }

    .a-vertical {
      margin-top: 10px !important;

      margin-bottom: 25px !important;
    }

    .a-color-base {
      margin-top: -2px;

      margin-left: 8px;
    }

    input {
      cursor: pointer;
    }

    .checkbox_eshop[disabled]:checked {
      background-color: #5a14e6 !important;
    }

    .a-text-bold {
      font-size: 15px;

      color: black;

      font-weight: bold;

      margin-left: -2px;

      margin-bottom: 20px;
    }

    .ul_eshop {
      list-style: none;

      margin: unset !important;
    }

    .a-link-normal {
      display: flex;
    }

    .c_pointer {
      cursor: pointer;
    }

    .all_filter {
      min-width: -moz-fit-content;
      min-width: fit-content;

      border-right: solid 1px #d5dbdb;
      border-top: 1px solid #E2E6E9;
    }

    .category_list {
      margin-left: 15px;
      cursor: pointer;
    }

    .extreme-flex {
      display: flex;

      align-content: space-between;

      flex-direction: row;

      justify-content: space-between;
    }

    .text_over_image {
      position: relative;

      margin-right: auto;

      margin-left: auto;

      text-align: center;

      margin-top: -55px;

      left: 5%;
    }

    .orange20 {
      font-size: 20px;

      color: orange;
    }

    .filler37 {
      height: 37px;

      opacity: 0.01;
    }

    .border-left {
      border-left: solid 1px #d5dbdb;
    }

    .items-description {
        display: flex;

        flex-direction: column;
    }



    /*Media Start*/

    @media screen and (min-width: 1024px) {

      .pc-only {
        display: block;
      }

      .mb-only {
        display: none;
      }

      .price-design {
        font-size: 12px;

        line-height: 20px;

        margin-top: 8px;
      }

      .price {
        font-size: 25px;
      }

      .symbol-design {
        position: relative;

        top: -0.3em;

        font-size: 11px;

        vertical-align: top;
      }

      .titre-container {
        padding: 14px 33px;

        margin-bottom: 0;

        border-bottom: solid 1px #d5dbdb;
      }

      .size-base {
        font-size: 14px;

        line-height: 20px;
      }

      .titre-page {
        margin-bottom: 4px;

        font-size: 28px;

        line-height: 36px;

        padding-bottom: 14px;

        width: -moz-max-content;

        width: max-content;
      }

      .text-bike {
        font-size: 15px;

        line-height: 25px;
      }

      .a-size-text {
      }

      .a-text-normal {
        font-weight: 400;

        font-style: normal;

        text-decoration: none !important;

        text-transform: none !important;
      }

      .a-color-base,
      .a_eshop:link.a-color-base,
      .a_eshop:visited.a-color-base {
        color: #0f1111;
      }

      .border-bottom {
        border-bottom: solid 1px #d5dbdb;
      }

      .item-vente {
        border-right: solid 1px #d5dbdb;

        border-bottom: solid 1px #d5dbdb;

        flex: 0 0 25%;

        padding-bottom: 10px;

        height: auto;

        width: auto;

        position: relative;

        padding-top: 15px;
        padding-bottom: 15px;
      }

      .item-vente-content {
        max-width: 320px;

        width: 100%;

        height: 100%;

        margin: 0 auto;
      }

      .mc_disable {
        pointer-events:none;
        opacity:0.5;
      }

      .a_eshop:hover {
        color: #00bafe;

        text-decoration: none;
      }

      .page-vente {
        border-right: solid 1px #d5dbdb;

        border-bottom: none;

        padding-bottom: 0;

        white-space: normal;

        overflow-x: visible;
      }

      .item-ensemble {
        display: flex;
      }

      .item-vente-ensemble {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(100px, 25%));
        flex-wrap: wrap;
        position: relative;
        height: 50%;
        width: 100%;
        min-height: 50%;
        max-height: 51%;
        border-top: 1px solid #E2E6E9;
      }

      .titre-box {
        padding-top: 20px;

        padding-bottom: 5px;

        font-weight: bold;

        width: 221px;
      }

      .li-box {
        border-top: none;
      }

      .ul_box li {
        list-style: none;

        word-wrap: break-word;

        margin: 0;
      }

      .box-ensemble {
        margin-top: 40px;

        display: block;

        border-bottom: none;

        padding-bottom: 0;

        white-space: normal;

        overflow-x: visible;

        margin-right: 20px;
      }

      .ul_eshop {
        padding: 0;

        margin: 0 0 0 18px;
      }

      .li-box-2 {
        padding-bottom: 5px;

        font-size: 16px;
      }

      .tree {
        color: black;
      }

      .ul-box {
        margin-left: 0;

        color: #0f1111;
      }

      .a_eshop {
        color: black;
        margin-right:8px;
        font-size:15px !important;
      }

      #maincategorie {
        font-size:15px !important;
      }

      .label_eshop {
        display: block;

        padding-left: 2px;

        padding-bottom: 2px;

        font-weight: normal;

      }

      .checkbox_eshop {
        bottom: -3px !important;

        vertical-align: top !important;
      }

      .checkmark {
        margin-left: 5px;

        text-decoration: none !important;
      }

      .img-w100 {
        width: 100%;
      }

      .img-w100:before {
        display: table;

        content: "";

        line-height: 0;

        font-size: 0;
      }

      .img-spacing-small {
        margin-bottom: 8px !important;
      }

      .align-center-horizontally {
        margin-left: auto;

        margin-right: auto;
      }

      .img-container {
        max-width: 226px;

        position: relative;
      }

      .img-container:after {
        content: "";

        display: block;

        padding-bottom: 100%;
      }

      .img-container img {
        position: absolute;

        margin: auto;

        top: 0;

        right: 0;

        bottom: 0;

        left: 0;

        max-width: 100%;

        max-height: 100%;

        vertical-align: top;

        border: 0;
      }

      .navbar-brand {
        max-width: 300px !important;
      }

      .flex-vertical {
        height: 100%;

        display: flex;

        flex-direction: column;

        justify-content: space-between;
      }

      .button {
        max-width: 230px;

        text-decoration: none;

        margin-bottom: 5px;

        margin-top: 5px;

        width: 230px;

        display: block;

        background-position: -10px -170px;
      }

      .login-item {
        border: 0;

        line-height: 15px;

        margin: 0;

        padding-left: 20px;

        padding-right: 20px;

        margin-top: 19px;

        margin-bottom: 17px;
      }

      .action-text {
        cursor: pointer;

        background-position: right -170px;

        display: block;

        height: 28px;

        position: relative;

        color: white;

        font-size: 12px;

        line-height: 28px;

        text-align: center;

        font-weight: 700;

        text-decoration: none !important;
      }

      .action-button {
        max-width: 230px;

        text-decoration: none !important;

        margin-bottom: 5px;

        margin-top: 5px;

        width: 150px;

        display: block;

        background-position: -10px -170px;

        white-space: nowrap;

        margin-right: auto;

        margin-left: auto;

        background-color: #2490ef;

        border-radius: 6px;

        box-shadow: rgba(0, 0, 0, 0.05) 0px 0.5px 0px 0px,
          rgba(0, 0, 0, 0.08) 0px 0px 0px 1px, rgba(0, 0, 0, 0.05) 0px 2px 4px 0px;
      }

      .multi-page {
        text-align: center;

        color: grey;

        margin-top: 15px;
      }

      #btn_prev {
        border-radius: 5px;

        font-weight: 500;
      }

      #btn_next {
        margin-right: 10px;

        border-radius: 5px;

        font-weight: 500;
      }

      .error {
        color: black;

        font-size: 50px;

        text-align: center;
      }

      #button-error {
        font-size: 15px;

        text-align: center;

        border-radius: 5px;

        border: none;

        background-color: #523815;

        color: white;

        margin-left: 210px;

        margin-top: 115px;
      }

      .btn-img {
        text-align: center;
      }

      #img-velo {

        max-width: 510px;

        height: 300px;

        margin-right: auto;

        margin-left: auto;

        width: 450px;
      }

      .mastercategory {
        font-weight: bold;
      }

      .border-bottom-items {
        border-bottom: solid 1px #d5dbdb;
      }
    }

    @media only screen and (hover: none) and (pointer: coarse),
      (max-width: 1024px) {


        .navbar-light {
      padding-right: 40px;
      padding-left: 40px;
      margin-top: 2rem;
    }

        .search-box{
            z-index: unset;
        }

      .price-design {
        font-size: 12px;

        line-height: 20px;

        margin-top: 8px;
      }

      .price {
        font-size: 25px;

        margin-bottom: 25px;

        bottom: 0;
      }

      .symbol-design {
        position: relative;

        top: -0.3em;

        font-size: 11px;

        vertical-align: top;
      }

      .titre-container {
        padding: 14px 18px;

        margin-left: auto;

        margin-right: auto;
      }

      .size-base {
        font-size: 14px;

        line-height: 20px;
      }

      .titre-page {
        margin-bottom: 50px;

        font-size: 28px;

        line-height: 36px;

        padding-bottom: 14px;

        z-index: 3;

        text-align: center;
      }

      .items-description {
        margin-top: 0.8rem !important;

        padding-right: 8px !important;

        padding: 0 25px;

        height: 100%;

        width: calc(0.58333 * (100vw - 8px));

        word-break: break-word;

        max-width: 50%;

        color: black !important;
      }

      .items-description:hover {
        color: black !important;
      }

      .text-bike {
        font-size: 1rem !important;

        line-height: 1.4 !important;

        font-style: normal !important;

        text-transform: none !important;

        text-decoration: none !important;

        color: #4c5a67;
      }

      .a-text-normal {
      }

      .a-color-base {
      }

      .item-vente {
        margin-bottom: 14px;

        border: 1px solid #f5f5f5;

        margin-right: 10px;
      }

      .item-vente-content {
        border-radius: 4px;

        transform: translate3d(0, 0, 0);
      }

      .item-ensemble {
        margin-bottom: 1.3rem;

        margin-top: -1.4rem !important;
      }

      .item-vente-ensemble {
        margin-left: -1.4rem;

        margin-right: -1.4rem;
      }

      .img-w100:before {
      }

      .img-spacing-small {
        font-weight: 400 !important;

        font-style: normal !important;

        text-transform: none !important;

        text-decoration: none !important;

        height: 100%;

        display: block;

        width: calc(0.41667 * (100vw - 8px));

        font-size: 1.3rem !important;

        line-height: 1.4 !important;
      }

      .align-center-horizontally {
      }

      .img-container {
        display: flex;

        justify-content: center;
      }

      .img-container:after {
      }

      .img-container img {
        max-height: 170px;

        vertical-align: top;

        max-width: 100%;

        border: 0;

        text-align: center;

        margin: 15px;
      }

      .flex-vertical {
        align-items: stretch;

        display: flex;

        flex-direction: row;

        flex-wrap: wrap;

        align-content: flex-start;

        margin: 0 0 0 4px;

        padding: 0;
      }

      a:hover {
        color: #00bafe;

        text-decoration: none;
      }

      .page-vente {
        border-right: solid 1px #d5dbdb;

        border-bottom: none;

        padding-bottom: 0;

        white-space: normal;

        overflow-x: visible;
      }

      .border-bottom-items {
        border-bottom: solid 1px #d5dbdb;
      }

      .tree {
        text-align: center;
      }

      #parent-id {
        margin-right: 5px;
      }

      .mastercategory {
        margin-left: 9px;
      }

      #filter-tree {
        padding-top: 10px;

        color: black;
      }

      .label_eshop {
        display: block;

        padding-bottom: 2px;

        font-weight: normal;

        color: black;
      }

      .checkbox_eshop {
        bottom: -3px !important;

        vertical-align: top !important;
      }

      .checkmark {
        margin-left: 5px;

        text-decoration: none !important;
      }

      .section-padding-top {
        padding-top: initial !important;
      }

      .navbar-brand {
        max-width: 300px !important;
      }

      .button {
        max-width: 230px;

        text-decoration: none;

        margin-bottom: 5px;

        margin-top: 5px;

        width: 230px;

        display: block;

        background-position: -10px -170px;
      }

      .login-item {
        border: 0;

        line-height: 15px;

        margin: 0;

        padding-left: 20px;

        padding-right: 20px;

        margin-top: auto;

        margin-bottom: auto;
      }

      .action-text {
        cursor: pointer;

        background-position: right -170px;

        display: block;

        height: 28px;

        position: relative;

        color: white;

        font-size: 12px;

        line-height: 28px;

        text-align: center;

        font-weight: 700;

        text-decoration: none !important;
      }

      .action-button {
        position: relative;

        max-width: 100px;

        text-decoration: none !important;

        width: 150px;

        display: block;

        background-position: -10px -170px;

        white-space: nowrap;

        background-color: #2490ef;

        border-radius: 6px;

        box-shadow: rgba(0, 0, 0, 0.05) 0px 0.5px 0px 0px,
          rgba(0, 0, 0, 0.08) 0px 0px 0px 1px, rgba(0, 0, 0, 0.05) 0px 2px 4px 0px;
      }

      /* error page */

      .error {
        color: black;

        font-size: 50px;

        text-align: center;
      }

      .multi-page {
        text-align: center;

        color: grey;

        margin-top: 15px;
      }

      #btn_prev {
        border-radius: 5px;

        font-weight: 500;
      }

      #btn_next {
        margin-right: 10px;

        border-radius: 5px;

        font-weight: 500;
      }

      #button-error {
        font-size: 15px;

        text-align: center;

        border-radius: 5px;

        border: none;

        background-color: #523815;

        color: white;

        margin-left: 210px;

        margin-top: 115px;
      }

      .btn-img {
        text-align: center;
      }

      #img-velo {

        max-width: 510px;

        height: 300px;

        margin-right: auto;

        margin-left: auto;

        width: 450px;
      }

      /* sticky mobile categories & filter */

      .categories-nav {
        height: 100%;

        width: 100%;

        position: fixed;


        background-color: white;

        z-index: 600;

        transform: translateY(100%);
      }

      .categories-cote {
        position: fixed;

        height: 100%;

        width: 100%;

        z-index: 10;
      }

      .nav-all {
        transform: translateY(100%);
      }

      .filter-nav {
        height: 100%;

        width: 100%;

        position: fixed;


        background-color: white;

        z-index: 600;

        transform: translateY(100%);
      }

      .filter-cote {
        position: fixed;

        height: 100%;

        width: 100%;

        z-index: 10;
      }

      .translate-visible {
        transform: translateY(-24.5%);

        transition: 0.5s ease;

        overflow: auto;

        left: 0px;
      }

      /* Btn filter/categories */

      #categories-label{
        margin-top:6px;
      }

      #filter-label{
        margin-top:6px;
      }

      #F_switch {
        cursor: pointer;

        height: 28px;

        font-size: 17px;

        line-height: 30px;

        text-align: center;

        width: 100px;

        border-radius: 5px;

        border: 1px solid #f4f4f4;

        margin-left: 100px;

        margin-top: 20px;

        margin-bottom: 30px;

        background-color: #f4f4f4;
      }

      #C_switch {
        cursor: pointer;

        height: 28px;

        font-size: 17px;

        line-height: 30px;

        text-align: center;

        width: 100px;

        border-radius: 5px;

        border: 1px solid #f4f4f4;

        margin-left: 100px;

        margin-top: 20px;

        margin-bottom: 30px;

        background-color: #f4f4f4;
      }

      #btn-filter {
        cursor: pointer;

        height: 28px;

        font-size: 17px;

        line-height: 30px;

        text-align: center;

        width: 100px;

        border-radius: 5px;

        border: 1px solid #f4f4f4;

        margin-right: 100px;

        margin-top: 20px;

        margin-bottom: 30px;

        background-color: #f4f4f4;
      }

      #btn-categories {
        cursor: pointer;

        height: 28px;

        font-size: 17px;

        line-height: 30px;

        text-align: center;

        width: 100px;

        border-radius: 5px;

        border: 1px solid #f4f4f4;

        margin-right: 100px;

        margin-top: 20px;

        margin-bottom: 30px;

        background-color: #f4f4f4;
      }

      #btnF-label {
        border-bottom: 1px solid #ebebeb;

        display: flex;

        justify-content: space-between;
      }

      #btnC-label {
        border-bottom: 1px solid #ebebeb;

        display: flex;

        justify-content: space-between;
      }


    .scrollnone{
        overflow: hidden;
    }
      /* Affichage de la barre de navigation sur Scroll Up */

      .categories-sticky {
        background-color: #fff;

        box-shadow: 10px 5px 5px #EEEEEE;

        width: 100%;

        border: 2px solid #ebebeb;

        z-index: 500;

        display: flex;

        justify-content: space-around;

        height: 50px;

        position: fixed;
        top: 0;
        left: 0;
        transition: top 0.2s ease-in-out;
        width: 100%;
      }

      .nav-up {
        top: -50px;
      }

      .navbar-collapse {
        z-index: 600;
      }

      .is-hidden {
        opacity: 0;

        -webkit-transform: translate(0, -60px);

        -webkit-transition: -webkit-transform 0.2s, background 0.3s, color 0.3s,
          opacity 0 0.3s;
      }

      .is-visible {
        opacity: 1;

        -webkit-transform: translate(0, 0);

        -webkit-transition: -webkit-transform 0.2s, background 0.2s, color 0.2s;
      }

      .dskt-only {
        display: none;
      }
    }

    @media (max-width: 991px) {
        #user_cart {
        right: 36px;
        position: absolute;
        top: 25px;
    }
    }

    @media (max-width: 380px) {
    #user_cart {
        right: -5px;
        position: absolute;
        top: 25px;
    }
    }

    @media (max-width: 332px) {
        #user_cart {
        right: 24px;
        position: absolute;
        top: 66px;
    }
    }
